.nameLogOut {
  color: #202124;
  font-size: 17px;
  margin-right: 20px;
}

@media screen and (max-width: 450px) {
  .nameLogOut {
    font-size: 12px;
  }
}
