.containerCallFiles {
  position: absolute;
  margin-top: 10px;
  flex-direction: column;
  z-index: 100;
}

.callFiles {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 2px #707070;
  width: 400px;
}

.callFilesBody {
  padding-bottom: 10px;
}

.callFilesBody,
.callFilesFooter {
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
}

.callFilesHeader {
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 5px;
  text-align: left;
  border-bottom: 2px solid #f7f7f7;
}

.callFilesFooter {
  padding-bottom: 10px;
  border-top: 2px solid #f7f7f7;
}

.callFilesHeaderTitle {
  color: #94a0ff;
  font-weight: 600;
}

@media screen and (max-width: 600px) {


  .callFiles {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 2px #707070;
    width: 70vw;
  }

}