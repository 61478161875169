.container {
  height: 100%;
  overflow-y: hidden;
}

.loadingContainer {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.callCard {
  position: relative;
  left: 5%;
  top: -96%;
}
.callCardContainer {
  background: white;
  border-radius: 25px;
  width: 450px;
  display: flex;
  padding: 10px 20px;
}
.callCardNamePatient {
  color: #031d94;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tagListItem {
  padding: 3px 15px !important;
  border-radius: 25px !important;
  background: #f9f9f9 !important;
}
.taglistContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagDot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 5px;
}
.tagLabel {
  opacity: 0.8;
  font-size: 11px;
  font-weight: 600;
}

.callHeaderUserStatusTextConnected,
.callHeaderUserStatusTextDisconnected {
  flex: 3;
  margin-bottom: 0;
  font-size: 1.1vw;
}

.callHeaderUserStatusTextConnected {
  color: #52c41a;
}

.callHeaderUserStatusTextDisconnected {
  color: #faad14;
}

.callHeaderStatusButton {
  min-width: 60%;
  border-radius: 20px !important;
  padding: 10px 50px !important;
  line-height: 0 !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

.callHeaderStatusTimerText > span {
  font-weight: bold;
}

.video {
  height: 100%;
  min-height: 50% !important;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #e4e8f3;
}
.doctorAlertContainer {
  position: absolute;
  padding: 20px;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.45);
  width: 300px;
  height: 200px;
  border-radius: 25px;
  color: white;
  font-weight: bold;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(241, 67, 67, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(224, 70, 65, 0.459);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.288);
  }
}
.doctorBlindedContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: all 1s ease;
  z-index: -99999;
}
.doctorCircleAlert {
  width: 80px;
  height: 80px;
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-red 2s infinite;
  margin: 10px;
}
.ownVideoContainer {
  position: absolute;
  width: 200px;
  height: 148px;
  bottom: 5%;
  left: 5%;
  z-index: 100;
  border: 2px solid white;
  border-radius: 8px;
}

.ownVideoElement {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  border-radius: 8px;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
.ownVideoElementAcceptSharingScreen {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  border-radius: 8px;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg); /* Safari and Chrome */
  -moz-transform: rotateY(0deg); /* Firefox */
}
.ownVideoSwitchCameraElement {
  position: relative;
  top: -170px;
  z-index: 100;
  left: 167px;
  cursor: pointer;
}
.ownVideoSwitchCamera {
  background: white;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.videoControlsContainer {
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 60px;
  left: 0;
  bottom: 5%;
  background: white;
  border-radius: 30px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.videoControlsImage {
  max-width: 4vw;
  width: 4vw;
  margin: 0 30px;
}

.chat {
  background-color: white;
  max-height: 100%;
}

.chatBox {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.chatBoxMessages {
  display: flex;
  flex-direction: column;
  flex: 8;
  background-color: white;
  overflow-y: auto;
}

.scrollableFeed {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
}

.scrollableFeed > *:last-child {
  margin-bottom: 30px;
}

.chatBoxInput {
  display: flex;
  width: 90%;
  background-color: white;
  align-self: flex-start;
}

.chatBoxInputTitle {
  border-bottom: 1px solid #d9d9d9;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 1vw;
}

.title {
  font-size: 1.5vw;
  color: rgba(89, 116, 175, 1);
}

.chatBoxInputArea {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 30px;
  padding: 10px;
  -webkit-box-shadow: -1px 0px 17px -3px #0000004a;
  -moz-box-shadow: -1px 0px 17px -3px rgba(0, 0, 0, 1);
  box-shadow: -1px 0px 17px -3px #0000004a;
}

.chatBoxInputText {
  flex: 8;
  padding: 0 10px;
}

.chatBoxInputComponent {
  border: none !important;
  font-size: 1vw;
}

.chatBoxInputSubmit {
  flex: 1;
  padding: 0px;
}

.chatBoxInputSubmitButton {
  background: rgb(85, 116, 247) !important;
  background: linear-gradient(
    90deg,
    rgba(85, 116, 247, 1) 25%,
    rgba(96, 195, 255, 1) 87%
  ) !important;
  color: white !important;
  border-radius: 50% !important;
  line-height: 0 !important;
}

.chatBoxMessage {
  display: flex;
  flex-direction: column;
  margin: 26px 50px 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  width: 65%;
  border-radius: 20px;
}

.chatBoxMessageMine {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin: 26px 50px 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  width: 65%;
  border-radius: 12px;
}

.chatBoxMessageBodyMine {
  background: rgb(85, 116, 247);
  background: linear-gradient(
    90deg,
    rgba(85, 116, 247, 1) 25%,
    rgba(96, 195, 255, 1) 87%
  );
  color: white;
  border-radius: 12px;
}

.chatBoxMessageBodyMine {
  display: flex;
  padding: 12px 16px;
  min-height: 40px;
  font-size: 1vw;
  text-align: left;
  position: relative;
}
.chatBoxMessageBodyMine:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-left-color: #60c3ff;
  margin-right: -14px;
  border-bottom-color: #60c3ff;
}

.chatBoxMessageBody {
  display: flex;
  background: white;
  padding: 12px 16px;
  min-height: 40px;
  font-size: 1vw;
  border-radius: 12px;
  position: relative;
  text-align: left;
  background-color: #dadada;
}
.chatBoxMessageBody:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-right-color: #dadada;
  margin-left: -14px;
  border-bottom-color: #dadada;
}

.participant {
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.participantVideo {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.largerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  height: 10%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
}

.horizontalSpacer {
  margin-left: 5%;
  margin-right: 5%;
}
.timeTranscurredAndEndCall {
  color: #2d3f8c;
  font-size: 18px;
  display: flex;
  justify-content: center;
}
.timeTranscurredText {
  display: block;
  font-size: 14px;
}

.title {
  font-size: 1.5vw;
  color: rgba(89, 116, 175, 1);
}

.appointContainer {
  border: 1px solid #e4e8f3;
}

.videoControlsCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  transition: box-shadow 0.4s;
}
.videoControlsCircle:hover,
.ownVideoSwitchCamera:hover {
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.26);
}
.videoControlsIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoControlsCameraDotsWhite,
.videoControlsCameraDotsBlue {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #6b7cff;
  margin: 1px;
}

.videoControlsCameraDotsWhite {
  background-color: white;
}
.videoControlsCameraDotsBlue {
  background: #6b7cff;
}
.videoControlsCameraContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.videoControlsCameraNumbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -10px;
  left: 33px;
}
.userHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userNameHeader {
  margin-left: 8px;
}

.allReady {
  color: #2d3f8c;
  font-weight: 350;
  font-size: 3vw;
}

.waitingDoctor {
  color: #2d3f8c;
  font-weight: 200;
  font-size: 2.5vw;
}

.waitingDoctorAgain {
  color: #2d3f8c;
  font-weight: 200;
  font-size: 1.2vw;
}

@media screen and (max-width: 1200px) {
  .userHeader {
    display: none;
  }

  .callHeaderStatusButton {
    min-width: 40%;
    border-radius: 20px !important;
    padding: 10px 50px !important;
    line-height: 0 !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-size: 2vw;
  }

  .timeTranscurredAndEndCall {
    color: #2d3f8c;
    font-size: 18px;
    display: flex;
    justify-content: center;
    padding-left: 40vw;
  }

  .chatBoxInputComponent {
    border: none !important;
    font-size: 3vw;
  }

  .chatBoxMessageBodyMine {
    display: flex;
    padding: 12px 16px;
    min-height: 40px;
    font-size: 3vw;
    text-align: left;
    position: relative;
  }

  .chatBoxMessageBody {
    display: flex;
    background: white;
    padding: 12px 16px;
    min-height: 40px;
    font-size: 3vw;
    border-radius: 12px;
    position: relative;
    text-align: left;
    background-color: #dadada;
  }
  .chatBoxMessageBody:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-right-color: #dadada;
    margin-left: -14px;
    border-bottom-color: #dadada;
  }
}
@media screen and (max-width: 920px) {
  .timeTranscurredText {
    display: none;
  }
  .largerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timeTranscurredAndEndCall {
    color: #2d3f8c;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }

  .chatBoxInputComponent {
    border: none !important;
    font-size: 3vw;
  }
}

@media screen and (max-width: 640px) {
  .timeTranscurredAndEndCall {
    color: #2d3f8c;
    font-size: 18px;
    display: table-column;
    padding-left: 40vw;
  }

  .callHeaderStatusButton {
    min-width: 30% !important;
    border-radius: 20px !important;
    padding: 3px 15px !important;
    line-height: 0 !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
  }

  .chatBoxInputComponent {
    border: none !important;
    font-size: 4vw;
  }

  .chatBoxMessageBodyMine {
    display: flex;
    padding: 12px 16px;
    min-height: 40px;
    font-size: 4vw;
    text-align: left;
    position: relative;
  }

  .chatBoxMessageBody {
    display: flex;
    background: white;
    padding: 12px 16px;
    min-height: 40px;
    font-size: 4vw;
    border-radius: 12px;
    position: relative;
    text-align: left;
    background-color: #dadada;
  }
  .chatBoxMessageBody:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-right-color: #dadada;
    margin-left: -14px;
    border-bottom-color: #dadada;
  }

  .callCard {
    position: relative;
    left: 5%;
    top: -96%;
  }
  .callCardContainer {
    background: white;
    border-radius: 25px;
    width: 70vw;
    display: flex;
    padding: 10px 20px;
  }
  .callCardNamePatient {
    color: #031d94;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2.5vw;
  }

  .tagLabel {
    opacity: 0.8;
    font-size: 2vw;
    font-weight: 600;
  }

  .tagListItem {
    padding: 1px 7px !important;
    border-radius: 20px !important;
    background: #f9f9f9 !important;
    margin-left: 1vw;
  }

  .videoControlsContainer {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 60px;
    left: -20%;
    bottom: 7%;
    background: white;
    border-radius: 30px;
  }

  .chatBoxInput {
    display: flex;
    width: 80%;
    background-color: white;
    align-self: flex-start;
  }
}

@media screen and (max-width: 850px) {
  .ownVideoContainer {
    position: absolute;
    width: 150px;
    height: 120px;
    bottom: 15%;
    left: 5%;
    z-index: 100;
    border: 2px solid white;
    border-radius: 8px;
  }

  .ownVideoSwitchCameraElement {
    position: relative;
    top: -130px;
    z-index: 100;
    left: 120px;
    cursor: pointer;
  }
}

@media screen and (max-width: 374px) {
  .ownVideoContainer {
    position: absolute;
    width: 120px;
    height: 80px;
    bottom: 20%;
    left: 5%;
    z-index: 100;
    border: 2px solid white;
    border-radius: 8px;
  }

  .ownVideoSwitchCameraElement {
    position: relative;
    top: -90px;
    z-index: 100;
    left: 90px;
    cursor: pointer;
  }
}
