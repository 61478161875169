.row {
  display: flex;
  align-items: center;
}

.largerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.horizontalSpacer {
  margin-left: 5%;
  margin-right: 5%;
}

.title {
  font-size: 1.5vw;
  margin-left: 18px;
  color: #6b7cff;
}

.consultRectangle {
  background-color: rgba(89, 116, 175, 1);
  border-radius: 5%;
  padding-left: 2%;
}

.consultRectangle p {
  color: white;
  font-size: 1vw;
}

.date {
  padding: 20px 0px 0px 0px;
  color: #b2b2b2;
  font-size: 1.7vw;
}
.consultNumber {
  padding: 20px 0px 0px 0px;
  color: #5974af;
  font-size: 1.4vw;
  font-weight: 700;
}
.subHeaderText {
  padding: 5px 30px;
}

.patientList {
  max-width: 100%;
  min-width: 100%;
}

.listItem {
  height: 5vh;
}

.listItemText {
  font-size: 20px;
}
.listItemTextHour {
  font-size: 15px;
  width: 10%;
  background: #f9f9f9;
  color: #696969;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listSeparatorLine {
  height: 0.3vh;
  background-color: rgba(89, 116, 175, 1);
  width: 110%;
  margin-left: -5%;
}

.contentRow {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}

.cardHeader {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardBody {
  background-color: white;
  max-height: 20vh;
  padding: 10px 20px;
  margin-top: 10%;
  margin-bottom: 20%;
}
.cardText {
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
}
.cardPatientData {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.rowItemList {
  margin: 0;
}
.cardButtonContainers {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}
.cardStartMeet button {
  padding: 20px 50px !important;
  background-color: #6a7bff !important;
  color: white !important;
  text-transform: uppercase !important;
  border-radius: 20px !important;
  line-height: 0 !important;
}
.cardStartMeet button:hover {
  background-color: #6a7bffc9 !important;
}
.cardCancelModal button {
  padding: 20px 50px !important;
  text-transform: uppercase !important;
  border-radius: 20px !important;
  line-height: 0 !important;
}

.rowItemListInline {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rowItemListInline:hover {
  transform: scale(1.002);
  -webkit-box-shadow: -2px 9px 33px -3px rgba(137, 137, 137, 0.3);
  -moz-box-shadow: -2px 9px 33px -3px rgba(137, 137, 137, 0.3);
  box-shadow: -2px 9px 33px -3px rgba(137, 137, 137, 0.3);
}
.tagListItem {
  padding: 3px 20px !important;
  border-radius: 20px !important;
  background: white !important;
}
.tagListItem:hover {
  background: white !important;
  border: 1px solid white !important;
  opacity: 1 !important;
}
.taglistContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagDot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 5px;
}

.noAppointmentSelected {
  text-align: center;
  margin-top: 7%;
  margin-right: 5%;
}
.namePatientItem {
  text-transform: uppercase;
  font-weight: bold;
}
.namePatientItem,
.rutItem,
.tagsItem {
  margin: 0px 15px;
}
.tagsItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.startMeetButton button {
  background: #6a7bff !important;
  padding: 20px 30px !important;
  line-height: 0 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}
.startMeetButton button[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.patiendHeaderMain {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.patientHeaderNums {
  display: flex;
  padding: 10px;
}
.patienHeaderDot {
  border-radius: 50%;
  margin-right: 8px;
  width: 10px;
  height: 10px;
}
.patientHeaderNomen {
  display: flex;
  border-radius: 20px;
  background: #f9f9f9;
  padding: 10px;
}
.patientHeaderText {
  color: #707070;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.patientHeaderTextNums {
  color: #707070;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.patientHeaderSquare {
  width: 13px;
  height: 13px;
  margin-right: 6px;
  border: 1px solid #d9d9d9;
}
.dataPatientItem {
  display: flex;
  width: 100%;
  align-items: center;
}
.itemAppointmentList {
  display: flex;
  width: 100%;
  align-items: center;
}
.tagLabel {
  opacity: 0.8;
  font-size: 11px;
}
.attachmentFilesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.attachmentFilesText {
  height: 38px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0 20px;
}
.attachmentFilesText:hover {
  background: #f5f5f5;
}
.attachmentFilesLabel {
  margin-left: 8px;
  color: #6b7cff;
}
.attachmentFilesPatient {
  color: #414141;
  font-weight: 600;
  text-transform: uppercase;
}
@media screen and (max-width: 1200px) {
  .listItemTextHour,
  .patientHeaderTextNums,
  .patientHeaderText {
    font-size: 12px;
  }
  .namePatientItem,
  .rutItem,
  .tagLabel {
    font-size: 10px;
  }
  .tagListItem {
    padding: 3px 15px !important;
  }

  .patientHeaderSquare {
    width: 12px;
    height: 12px;
  }
  .tagDot {
    margin-right: 5px;
  }
  .startMeetButton button {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 900px) {
  .patiendHeaderMain {
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .dataPatientItem {
    align-items: normal;
    flex-direction: column;
  }
  .tagsItem {
    justify-content: end;
    align-items: unset;
  }
  .namePatientItem,
  .rutItem,
  .tagsItem,
  .filesItem,
  .statusItem {
    padding: 2px 0px;
  }
  .listItemTextHour {
    width: 20%;
  }
  .rowItemListInline {
    width: 80%;
  }
}
@media screen and (max-width: 700px) {
  .tagsItem {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .patiendHeaderMain {
    flex-direction: row;
  }
  .patientHeaderNomen {
    flex-wrap: wrap;
  }
  .rowItemList {
    flex-wrap: nowrap !important;
  }
  .startMeetButton button {
    padding: 10px 30px !important;
    font-size: 10px !important;
  }
  .cardHeader {
    flex-direction: column;
    padding: 0px;
  }
  .cardPatientData {
    padding: 20px;
  }
  .cardBody {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .cardCancelModal button,
  .cardStartMeet button {
    padding: 10px 30px !important;
    font-size: 12px !important;
    margin: 0 10px !important;
  }
}
@media screend and (max-width: 550px) {
  .startMeetButton button {
    padding: 10px 20px !important;
  }
}
@media screen and (max-width: 500px) {
  .listItemTextHour {
    width: 25%;
  }
  .rowItemListInline {
    width: 75%;
    padding: 10px !important;
  }
  .tagListItem {
    padding: 0 15px !important;
  }
  .namePatientItem,
  .tagsItem {
    padding: 10px 0px;
  }
  .patientHeaderNums {
    flex-direction: column;
  }
  .dataPatientItem {
    flex-direction: row;
    align-items: center;
  }
  .itemAppointmentList {
    flex-direction: column;
  }
  .subHeaderText {
    padding: 10px 10px;
  }
  .rutItem {
    display: none;
  }
  .date,
  .consultNumber {
    padding: 10px 0px 0px 0px;
  }
}

@media screen and (max-width: 420px) {
  .cardPatientData,
  .cardText {
    font-size: 12px;
  }
  .namePatientItem,
  .tagsItem {
    padding: 10px 0px;
    font-size: 9px;
  }
  .listItemTextHour {
    font-size: 9px;
  }
  .tagLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
  }
}

@media screen and (max-width: 380px) {
  .startMeetButton button {
    padding: 10px 40px !important;
  }
}

@media screen and (max-width: 330px) {
  .tagLabel {
    font-size: 7px;
  }
  .startMeetButton button {
    padding: 10px 30px !important;
  }
  .tagLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30px;
  }
}

.patientListHeight {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-height: 20vh;
  border: 1px solid #eaeaea;
}

.fatherPatientListHeight {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-height: 60.2vh;
  border: 1px solid #eaeaea;
}

@media (min-width: 1200px) {
  .fatherPatientListHeight {
    max-height: 62.5vh;
  }
}

@media (max-width: 1024px) {
  .fatherPatientListHeight {
    max-height: 59.2vh;
  }
}

@media (max-width: 768px) {
  .fatherPatientListHeight {
    max-height: 61.5vh;
  }
}

@media (max-width: 576px) {
  .fatherPatientListHeight {
    max-height: 66.6vh;
  }
}
