.filename {
  color: #7888ff !important;
  font-weight: 600;
}

@media screen and (max-width: 600px) {

  .filename {
    font-size: 3vw;
  }
}
