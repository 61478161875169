.cardAblyContainer {
  width: auto;
  height: auto;
  position: relative;
}
.cardAblyContent {
  position: absolute;
  top: 15%;
  right: 3%;
  z-index: 100000;
  width: 28%;
  height: 50px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(130, 130, 130, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(130, 130, 130, 1);
  box-shadow: 0px 0px 8px 0px rgba(130, 130, 130, 1);
}
.cardAblyConnection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  animation: linear 1;
  animation-name: showUp;
  animation-duration: 0.2s;
}
.cardAblyPulseIconConnected {
  animation: pulse-green 2s infinite;
  transform: scale(1);
  border-radius: 50%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardAblyPulseIconDisconnect {
  animation: pulse-blue 2s infinite;
  transform: scale(1);
  border-radius: 50%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes showUp {
  0% {
    right: -10px;
  }
  100% {
    right: 10px;
  }
}
@keyframes pulse-blue {
  0% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  100% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}
@keyframes pulse-green {
  0% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  100% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
@media screen and (max-width: 900px) {
  .cardAblyContent {
    right: 3%;
  }
}
@media screen and (max-width: 600px) {
  .cardAblyContent {
    width: 90%;
    right: 5%;
  }
}
