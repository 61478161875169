/* Appointment Survey Component */

/* Desktop */

.stepsHeader {
  display: flex !important;
}

.stepsContent {
  flex-direction: column !important;
}

.borderNone {
  border: none !important;
  background-color: #f9f9f9;
}

.borderNone {
  border: none !important;
}

.stepsContent {
  flex-direction: column !important;
  text-align: start;
  background-color: transparent !important;
}

.borderNone {
  border: none !important;
  background-color: transparent !important;
}

.antCard {
  background-color: transparent !important;
  border: 2px solid #f0f0f0;
  min-width: 25em;
}

.ant-rate-star .ant-rate-star-zero {
  color: #fafafa;
}

.customMarginRateDesktop {
  margin-top: 40px;
}

.sendSurveyButton {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratingTitle {
  color: #6b7cff;
  font-weight: 320;
  font-size: 3.5vw;
  text-align: center;
}

.stepsHeaderGap {
  margin-top: 2%;
}

.ant-rate-star.ant-rate-star-full,
.ant-rate-star.ant-rate-star-zero,
.ant-rate-star.ant-rate-star-half.ant-rate-star-active {
  transition: transform 0s !important;
}

.ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
  transform: scale(0.91) !important;
}

.ant-rate-star.ant-rate-star-full:hover {
  transform: scale(0.91) !important;
}

.ant-rate-star.ant-rate-star-zero:hover {
  transform: scale(0.91) !important;
}

.troublesTitle {
  color: '#2D3F8C' !important;
}

.ant-modal {
  display: flex;
  width: fit-content !important;
}

.sendSurveyButton {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marginLeftOverPx {
  justify-content: center !important;
}

.ratingSubTitle {
  color: #57619a;
  font-weight: 200;
  font-size: 1.7vw;
}

.addCommentOrIssues {
  color: '#99a5ff';
  font-weight: bold;
  transition-delay: 2s;
  justify-content: center;
}

.sendButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-rate-text {
  font-size: 0.7vw;
}

.starsStyles {
  font-size: 1.3em;
}

.cardSurveyData {
  display: flex;
  justify-content: center;
  /* flex-direction: column-reverse; */
  padding: 20px 0px 10px 0px;
}

.stepsActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animationRating {
  animation: shake 1.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.ant-rate-star:hover {
  width: 120%;
  height: 120%;
}

.ant-steps-item-icon {
  margin-left: 0px !important;
}

.ant-steps-item-container {
  text-align: center !important;
}

.whatIsProblemTitle {
  color: #47619a;
}

/* /Appointment Survey Component */

/*  Movil */

@media only screen and (max-width: 900px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsHeader {
    display: flex !important;
    width: 6.5vw;
  }

  /* .ant-steps-label-vertical .ant-steps-dot {
    width: 5rem;
  } */

  .borderNone {
    border: none !important;
    background-color: #f9f9f9;
  }

  .borderNone {
    border: none !important;
  }

  .stepsContent {
    flex-direction: column !important;
    text-align: start;
    background-color: transparent !important;
    /* width: fit-content; */
  }

  .borderNone {
    border: none !important;
    background-color: transparent !important;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 25em;
  }

  .ant-rate-star .ant-rate-star-zero {
    color: #fafafa;
  }

  .sendSurveyButton {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 900ms ease;
  }

  .ratingTitle {
    color: #6b7cff;
    font-weight: 320;
    font-size: 5.5vw;
    margin-top: 0em;
    text-align: center;
  }

  .ant-rate-star.ant-rate-star-full,
  .ant-rate-star.ant-rate-star-zero,
  .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
    transition: transform 0s !important;
  }

  .ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
    transform: scale(0.91) !important;
  }

  .ant-rate-star.ant-rate-star-full:hover {
    transform: scale(0.91) !important;
  }

  .ant-rate-star.ant-rate-star-zero:hover {
    transform: scale(0.91) !important;
  }

  .ratingSubTitle {
    color: #57619a;
    font-weight: 200;
    font-size: 3vw;
    text-align: center;
  }

  .ant-rate-text {
    font-size: 3vw;
  }

  .troublesTitle {
    color: '#2D3F8C' !important;
  }

  .ant-modal {
    display: flex;
    width: fit-content !important;
  }

  .addCommentOrIssues {
    color: '#99a5ff';
    cursor: pointer;
    font-weight: bold;
    transition-delay: 2s;
    justify-content: center;
  }

  .sendButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .starsStyles {
    font-size: 1.3em;
  }

  .customMarginRateDesktop {
    margin-top: 0px;
  }

  .cardSurveyData {
    display: flex;
    justify-content: center;
    padding: 20px 0px 10px 0px;
  }

  .stepsActions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .animationRating {
    animation: shake 1.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .ant-rate-star:hover {
    width: 120%;
    height: 120%;
  }
}

@media only screen and (max-width: 1030px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 9.5rem;
    width: 9.5rem;
    display: flex;
    margin-left: 1rem;
    justify-content: center;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media only screen and (max-width: 900px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 9.5rem;
    width: 9.5rem;
    display: flex;
    margin-left: 1rem;
    justify-content: center;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 750px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 4rem;
    width: 4rem;
    display: flex;
    margin-left: 2.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 730px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 4rem;
    width: 4rem;
    display: flex;
    margin-left: 2.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 680px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 4rem;
    width: 4rem;
    display: flex;
    margin-left: 2.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 600px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 4rem;
    width: 4rem;
    display: flex;
    margin-left: 2.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 599px) {

  /* .marginLeftOverPx {
    margin-left: -0.2rem;
  } */
  .stepsItems {
    max-width: 8rem;
    width: 8rem;
    display: flex;
    margin-left: 0.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 520px) {

  .dot {
    width: 120px;
  }
  
  .stepsItems {
    max-width: 7rem;
    width: 7rem;
    display: flex;
    margin-left: 0.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 470px) {

  /* .marginLeftOverPx {
    margin-left: -1.2rem;
  } */
  .stepsItems {
    max-width: 6rem;
    width: 6rem;
    display: flex;
    margin-left: 0.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}


@media screen and (max-width: 450px) {
  .whatIsProblemTitle {
    font-size: 1.2rem;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: red !important;
  }

  .addCommentOrIssues {
    font-size: 0.85rem !important;
    font-size: 1em;
    font-weight: bold;
    transition-delay: 2s;
    justify-content: center;
  }

  .ratingTitle {
    color: #6b7cff;
    font-weight: 320;
    font-size: 5.9vw;
    text-align: center;
  }

  .ratingSubTitle {
    color: #57619a;
    font-weight: 200;
    font-size: 3vw;
    text-align: center;
  }

  /* .marginLeftOverPx {
    margin-left: -1.2rem;
  } */
  .stepsItems {
    max-width: 6rem;
    width: 6rem;
    display: flex;
    margin-left: 0.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 420px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 5rem;
    width: 5rem;
    display: flex;
    margin-left: 0.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 20em;
    width: 20em;
  }
}

@media screen and (max-width: 380px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 4rem;
    width: 4rem;
    display: flex;
    margin-left: 0.3rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 19em;
    width: 19em;
  }
}

@media screen and (max-width: 350px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 4rem;
    width: 4rem;
    display: flex;
    margin-left: 0.1rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 19em;
    width: 19em;
  }

  .ant-steps-item-title {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 322px) {
  .marginLeftOverPx {
    justify-content: center !important;
  }

  .stepsItems {
    max-width: 4rem;
    width: 4rem;
    display: flex;
    margin-left: 0.1rem;
    justify-content: flex-start;
  }

  .antCard {
    background-color: transparent !important;
    border: 2px solid #f0f0f0;
    min-width: 19em;
    width: 19em;
  }

  .ant-steps-item-title {
    font-size: 10px !important;
  }
}